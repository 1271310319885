import React from "react";

const Image = ({ image, cssClass }) => {
  return (
    <div
      className={
        cssClass
          ? ["module module__image", cssClass].join(" ")
          : "module module__image"
      }
    >
      <div className="module__box">
        {image ? (
          <img
            className="module__image-img"
            src={image.sourceUrl}
            alt={image.altText}
            title={image.title}
            srcSet={image.srcSet}
            // sizes={image.sizes}
            loading={"lazy"}
            width={image.mediaDetails.width}
            height={image.mediaDetails.height}
          />
        ) : null}
      </div>
    </div>
  );
};

export default Image;
